import React, { CSSProperties, useState } from 'react';
import toast from 'react-hot-toast';
import styled, { ThemeProvider } from 'styled-components';
import API from 'utils/API';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { TextArea } from 'styleguide/Inputs';
import { Modal } from 'styleguide/Modal';
import { H1 } from 'styleguide/Texts';
import { observer } from 'mobx-react-lite';
import { themeState } from 'styleguide/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  border-radius: 10px;
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const LowerContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  display: flex;
  flex: 1;
  align-self: stretch;
  padding: 12px 20px;
  border: 1px solid ${colors.neutral200};
  border-radius: 6px;
  align-self: stretch;
`;

interface InputModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FeedbackModal = observer(({ isOpen, onClose }: InputModalProps) => {
  const [input, setInput] = useState('');

  return (
    <ThemeProvider theme={themeState.current}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Container>
          <UpperContainer>
            <H1 style={{ marginBottom: 20 }}>{'Send us feedback 🙏'}</H1>
            <div style={{ display: 'flex', width: '100%' }}>
              <StyledTextArea
                placeholder="Add your feedback"
                minRows={4}
                value={input}
                onChange={e => {
                  const { value } = e.currentTarget;

                  if (value.length > 800) return;

                  setInput(value);
                }}
              />
            </div>
          </UpperContainer>
          <LowerContainer>
            <SecondaryButton
              style={styles.button}
              title="Cancel"
              onClick={() => {
                onClose();
                setInput('');
              }}
            />
            <div style={{ width: 20 }} />
            <PrimaryButton
              style={styles.button}
              title={'Submit'}
              onClick={async () => {
                onClose();

                await toast.promise(API.postFeedback('web-app-feedback', input), {
                  loading: 'Sending feedback...',
                  success: 'Feedback sent!',
                  error: 'Failed to create folder',
                });

                setInput('');
              }}
              enabled={!!input}
            />
          </LowerContainer>
        </Container>
      </Modal>
    </ThemeProvider>
  );
});

const styles: { [key: string]: CSSProperties } = {
  button: {
    display: 'flex',
    flex: 1,
  },
};
