var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d2ca6d7b11272d1b2fc5b260af82363f597dcfcf"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://ecb3ca7fe35e46c98c3ada4a8981d521@o276807.ingest.sentry.io/4504763926642688',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  enabled: process.env.NEXT_PUBLIC_ENV === 'production',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeSend: event => {
    /**
     * https://hevy.sentry.io/issues/4620668251/events/2c35954748cd4c0c8cf8b03798bb94fe/?project=4504726557425664&query=&referrer=previous-event&sort=user&statsPeriod=14d&stream_index=0
     * Seems to be a non-user-impacting drag and drop error
     */
    if (event.message === 'Invariant failed') {
      return null;
    }

    // Seems to be a non-user-impacting issue on Instagram's browser: https://developers.facebook.com/community/threads/320013549791141/
    if (event.message?.includes(`Can't find variable: _AutofillCallbackHandler`)) {
      return null;
    }
    return event;
  },
});
