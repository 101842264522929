import React from 'react';
import styled, { ThemeProps, ThemeProvider, css } from 'styled-components';
import { useRouter } from 'next/router';
import { MIN_WIDTH_FOR_SIDEBAR_NAVIGATION, themeState } from 'styleguide/theme';
import { Theme } from 'styleguide/types';
import { DividerLine } from 'styleguide/DividerLine';
import { observer } from 'mobx-react-lite';
import { stores } from 'state/stores';
import { TextSMMedium } from 'styleguide/Texts';
import { ThemedLogo } from 'components/svg/ThemedLogo';
import { ThemedIcon } from 'styleguide/ThemedIcon';
import { colors } from 'styleguide/colors';
import NavChevron from './assets/chevron.svg';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { NavBarMainContent } from './components/NavBarMainContent';
import { modal } from 'components/Modals/ModalManager';
import { NavBarUserSearchViewModel } from './components/NavBarUserSearchViewModel';
import Link from 'next/link';

const HorizontalNavContentContainer = styled.nav`
  display: flex;
  max-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px;
  margin: 0px auto;
  align-items: center;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
`;

const DesktopNavBarContainer = styled.div`
  flex-direction: column;
  display: flex;
  height: 100vh;
  width: 256px;
  min-width: 256px;
  position: sticky;
  top: 0;
  ${(props: ThemeProps<Theme>) =>
    props.theme.name === 'light' &&
    css`
      border-right-style: solid;
      border-right-width: 1px;
      border-right-color: ${colors.neutral150};
    `}
  @media (max-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px) {
    display: none;
  }
`;

const DownloadButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${Spacing.md - Spacing.xs}px;
  margin-right: ${Spacing.md - Spacing.xs}px;
  text-decoration: none;
  @media (min-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px) {
    display: none;
  }
`;

const LoginButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${Spacing.md - Spacing.xs}px;
  padding-right: ${Spacing.sm}px;
  text-decoration: none;
  @media (max-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px) {
    display: none;
  }
`;

const SignupButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${Spacing.md - Spacing.xs}px;
  padding-left: ${Spacing.sm}px;
  text-decoration: none;
  @media (max-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px) {
    display: none;
  }
`;

const UnauthenticatedLogoContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-top: ${Spacing.md}px;
  padding-bottom: ${Spacing.md}px;
  flex-basis: 0%;
  @media (max-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px) {
    margin-left: ${Spacing.md}px;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spacing.lg}px ${Spacing.md}px ${Spacing.sm}px ${Spacing.md}px;
`;

const NavOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const OnboardingStatusContainer = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
  gap: 18px;
  @media (max-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px) {
    display: none;
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  @media (max-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px) {
    flex-direction: column;
  }
`;

const UnauthenticatedAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MobileOnlyWrapper = styled.div`
  @media (min-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION + 1}px) {
    display: none;
  }
`;

interface UnauthenticatedNavProps {
  route: string;
}

const UnauthenticatedNav = ({ route }: UnauthenticatedNavProps) => {
  const downloadButtonUrlFromPath: { [key: string]: string } = {
    '/routine/[shortId]': 'https://hevyapp.app.link/XCTvJJnBTvb',
    '/workout/[workoutId]': 'https://hevyapp.app.link/f5ZLrzFBTvb',
    '/program/[programId]': 'https://hevyapp.app.link/UXplsQ6QCMb',
  };

  const getLoginSignupButtonPaths: string[] = [
    '/routine/[shortId]',
    '/workout/[workoutId]',
    '/user/[username]',
    '/program/[programId]',
  ];

  const router = useRouter();

  return (
    <>
      <LeftContainer>
        <>
          <UnauthenticatedLogoContainer>
            <Link href="/">
              <ThemedLogo />
            </Link>
          </UnauthenticatedLogoContainer>
        </>
      </LeftContainer>

      {getLoginSignupButtonPaths.includes(route) && (
        <>
          <LoginButtonContainer href={`/login?postLoginPath=${router.asPath}`}>
            <PrimaryButton
              style={{
                paddingRight: Spacing.md,
                paddingLeft: Spacing.md,
                paddingBottom: Spacing.sm,
                paddingTop: Spacing.sm,
                borderRadius: Spacing.xs,
              }}
              title="Log in"
            ></PrimaryButton>
          </LoginButtonContainer>
          <SignupButtonContainer href={`/signup`}>
            <SecondaryButton
              style={{
                paddingRight: Spacing.md,
                paddingLeft: Spacing.md,
                paddingBottom: Spacing.sm,
                paddingTop: Spacing.sm,
                borderRadius: Spacing.xs,
              }}
              title="Sign Up"
            ></SecondaryButton>
          </SignupButtonContainer>
        </>
      )}

      {!!downloadButtonUrlFromPath[route] && (
        <DownloadButtonContainer href={downloadButtonUrlFromPath[route]}>
          <PrimaryButton
            style={{
              paddingRight: Spacing.md,
              paddingLeft: Spacing.md,
              paddingBottom: Spacing.sm,
              paddingTop: Spacing.sm,
              borderRadius: Spacing.xs,
            }}
            title="Download"
          ></PrimaryButton>
        </DownloadButtonContainer>
      )}
    </>
  );
};

interface LoggedInNavProps {
  pathname: string;
  showProCta: boolean;
}

const MobileTopNavBar = styled.div`
  overflow: hidden;
`;

const LoggedInMobileTopNavBar = observer(() => {
  return (
    <MobileTopNavBar
      style={{
        borderBottomColor: themeState.current.neutral150,
        backgroundColor: themeState.current.secondary,
      }}
    >
      <View
        onClick={() => {
          modal.openMobileNavBarModal();
        }}
        style={{ padding: Spacing.md, cursor: 'pointer' }}
      >
        <ThemedIcon type="menu" />
      </View>
    </MobileTopNavBar>
  );
});

const LoggedInDesktopNav = (props: LoggedInNavProps) => {
  const userSearchVm = new NavBarUserSearchViewModel();
  return <LoggedInDesktopNavInner {...props} vm={userSearchVm} />;
};

const LoggedInDesktopNavInner = observer(
  ({ pathname, showProCta, vm }: LoggedInNavProps & { vm: NavBarUserSearchViewModel }) => (
    <DesktopNavBarContainer
      style={{
        borderRightColor: themeState.current.name === 'light' ? colors.neutral150 : colors.darkGrey,
        backgroundColor: themeState.current.secondary,
      }}
    >
      <NavOptionsContainer>
        <LogoContainer>
          <Link href="/">
            <ThemedLogo />
          </Link>
        </LogoContainer>
        <NavBarMainContent
          pathname={pathname}
          showProCta={showProCta}
          isSettingsEnabled={true}
          vm={vm}
        />
      </NavOptionsContainer>
    </DesktopNavBarContainer>
  ),
);

interface OnboardingNavProps {
  screenName: string;
}

const OnboardingNav = ({ screenName }: OnboardingNavProps) => {
  const steps: string[] = ['Username', 'Units', 'Plans', 'Get started'];
  const currentIndex =
    screenName === 'username'
      ? 0
      : screenName === 'units'
      ? 1
      : screenName === 'plans'
      ? 2
      : screenName === 'finish'
      ? 3
      : -1;

  return (
    <View style={{ flexDirection: 'row', width: '100vw' }}>
      <UnauthenticatedLogoContainer>
        <Link href="/">
          <ThemedLogo />
        </Link>
      </UnauthenticatedLogoContainer>
      <OnboardingStatusContainer>
        {steps.map((title, index) => (
          <>
            {index > 0 && <NavChevron />}
            <TextSMMedium
              style={{
                fontWeight: 600,
                ...(index === currentIndex ? { color: colors.primary500 } : undefined),
              }}
            >
              {title}
            </TextSMMedium>
          </>
        ))}
      </OnboardingStatusContainer>

      <View style={{ flex: 1 }} />
    </View>
  );
};

const ThemedHorizontalNavBar = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <div style={{ backgroundColor: themeState.current.secondary }}>
      <HorizontalNavContentContainer>{children}</HorizontalNavContentContainer>
      {themeState.current.name === 'light' && <DividerLine />}
    </div>
  );
};

interface HevyAppNavigationWrapperProps {
  isAuthenticated: boolean;
  children: JSX.Element | JSX.Element[];
}

export const HevyAppNavigationWrapper = observer(
  ({ isAuthenticated, children }: HevyAppNavigationWrapperProps) => {
    const router = useRouter();

    const appState = getAppState(isAuthenticated, router.route);

    const App = ((): JSX.Element => {
      switch (appState) {
        case 'welcome-to-pro':
          return <>{children}</>;
        case 'authenticated':
          return (
            <AppContainer>
              <>
                <LoggedInDesktopNav
                  pathname={router.pathname}
                  showProCta={stores.subscription.hasFetched ? !stores.subscription.isPro : false}
                />
                <MobileOnlyWrapper>
                  <ThemedHorizontalNavBar>
                    <LoggedInMobileTopNavBar />
                  </ThemedHorizontalNavBar>
                </MobileOnlyWrapper>
              </>
              {children}
            </AppContainer>
          );
        case 'password-reset':
          return (
            <UnauthenticatedAppContainer>
              <ThemedHorizontalNavBar>
                <UnauthenticatedNav route={router.route} />
              </ThemedHorizontalNavBar>
              {children}
            </UnauthenticatedAppContainer>
          );
        case 'onboarding':
          return (
            <UnauthenticatedAppContainer>
              <ThemedHorizontalNavBar>
                <OnboardingNav screenName={router.query.screenName as string} />
              </ThemedHorizontalNavBar>
              {children}
            </UnauthenticatedAppContainer>
          );
        case 'unauthenticated':
          return (
            <UnauthenticatedAppContainer>
              <ThemedHorizontalNavBar>
                <UnauthenticatedNav route={router.route} />
              </ThemedHorizontalNavBar>
              {children}
            </UnauthenticatedAppContainer>
          );
      }
    })();

    return <ThemeProvider theme={themeState.current}>{App}</ThemeProvider>;
  },
);

type AppState =
  | 'unauthenticated'
  | 'authenticated'
  | 'onboarding'
  | 'welcome-to-pro'
  | 'password-reset';

const getAppState = (isAuthenticated: boolean, route: string): AppState => {
  const isOnboarding =
    route === '/onboarding/[screenName]' || route === '/coach/onboarding/[screenName]';

  if (isOnboarding) {
    return 'onboarding';
  }

  const isOnWelcomeToPro = route === '/welcome-to-pro';

  if (isOnWelcomeToPro) {
    return 'welcome-to-pro';
  }

  const isResettingPassword = route.includes('/update_password');

  if (isResettingPassword) {
    return 'password-reset';
  }

  if (isAuthenticated) {
    return 'authenticated';
  }

  return 'unauthenticated';
};
