import React from 'react';
import { makeAutoObservable } from 'mobx';
import {
  DestructiveConfirmationModal,
  DestructiveConfirmationModalProps,
} from './DestructiveConfirmationModal';
import { observer } from 'mobx-react-lite';
import { ConfirmationModal, ConfirmationModalProps } from './ConfirmationModal';
import { PaywallModal, PaywallModalProps, PaywallModalType } from './PaywallModal';
import { WelcomeModal, WelcomeModalProps } from './WelcomeModal';
import { OkModal, OkModalProps } from './OkModal';
import { CoachInviteModal, CoachInviteModalProps } from './CoachInviteModal';
import { useRouter } from 'next/router';
import { UserListModal, UserListModalProps } from './UserList/UserListModal';
import { UserListViewModel } from './UserList/UserListViewModel';
import { FollowerListViewModel } from './UserList/FollowerListViewModel';
import { UserListMode } from './UserList/types';
import { MobileNavBarModal, MobileNavBarProps } from 'components/NavBar/MobileNavBarModal';
import { ExerciseLibraryViewModel } from 'components/ExerciseLibrary/ExerciseLibraryViewModel';
import { ExerciseLibraryModal, ExerciseLibraryModalProps } from './ExerciseLibraryModal';
import { CustomExerciseViewModel } from './CustomExercise/CustomExerciseViewModel';
import {
  CustomExerciseModal,
  CustomExerciseModalProps,
} from './CustomExercise/CustomExerciseModal';
import { AlertModal, AlertModalProps } from './AlertModal';
import { CommentModal, CommentModalProps } from './Comment/CommentModal';
import { CommentModalViewModel } from './Comment/CommentModalViewModel';
import { RoutineMetadata } from 'hevy-shared';
import { RoutineListModal, RoutineListModalProps } from './RoutineListModal';

class ModalManagerState {
  constructor() {
    makeAutoObservable(this, {
      destructiveConfirmationOnConfirm: false,
      confirmationOnConfirm: false,
      paywallOnClose: false,
      paywallOnCancel: false,
    });
  }

  /**
   * Routine List Modal
   */
  isRoutineListModalOpen = false;
  routines: RoutineMetadata[] = [];
  get routineListProps(): RoutineListModalProps {
    return {
      routines: this.routines,
      isOpen: this.isRoutineListModalOpen,
      onClose: this.dismissRoutineListModal,
    };
  }

  openRoutineListModal = (props: Omit<RoutineListModalProps, 'isOpen' | 'onClose'>) => {
    this.routines = props.routines;
    this.isRoutineListModalOpen = true;
  };

  private dismissRoutineListModal = () => {
    this.isRoutineListModalOpen = false;
  };

  /**
   * OkModal
   */
  isOkModalOpen = false;
  okTitle = '';
  okSubtitle = '';
  okSubmitTitle = '';
  get okProps(): OkModalProps {
    return {
      title: this.okTitle,
      subtitle: this.okSubtitle,
      submitButtonTitle: this.okSubmitTitle,
      isOpen: this.isOkModalOpen,
      onClose: this.dismissOkModal,
    };
  }

  ok = (props: Omit<OkModalProps, 'isOpen' | 'onClose'>) => {
    this.okTitle = props.title;
    this.okSubtitle = props.subtitle;
    this.okSubmitTitle = props.submitButtonTitle;
    this.isOkModalOpen = true;
  };

  private dismissOkModal = () => {
    this.isOkModalOpen = false;
  };

  /**
   * AlertModal
   */
  isAlertModalOpen = false;
  alertModalTitle = '';
  alertConfirmButtonStyle?: 'primary' | 'destructive';
  alertModalBody: string | undefined = undefined;
  alertConfirmButtonTitle = '';
  alertCancelButtonTitle?: string;
  onAlertClosedCallback?: (isConfirmed: boolean) => void;
  get alertProps(): AlertModalProps {
    return {
      title: this.alertModalTitle,
      body: this.alertModalBody,
      confirmButtonTitle: this.alertConfirmButtonTitle,
      cancelButtonTitle: this.alertCancelButtonTitle,
      isOpen: this.isAlertModalOpen,
      onClose: this.dismissAlertModal,
      confirmButtonStyle: this.alertConfirmButtonStyle,
    };
  }

  openAlertModal = (props: Omit<AlertModalProps, 'isOpen'>) => {
    this.alertModalTitle = props.title;
    this.alertModalBody = props.body;
    this.alertConfirmButtonStyle = props.confirmButtonStyle;
    this.alertConfirmButtonTitle = props.confirmButtonTitle;
    this.alertCancelButtonTitle = props.cancelButtonTitle;
    this.isAlertModalOpen = true;
    this.onAlertClosedCallback = props.onClose;
  };

  private dismissAlertModal = (confirmed: boolean) => {
    this.onAlertClosedCallback?.(confirmed);
    this.isAlertModalOpen = false;
    this.onAlertClosedCallback = undefined;
  };

  /**
   * WelcomeModal
   */
  isWelcomeModalOpen = false;
  get welcomeProps(): WelcomeModalProps {
    return {
      isOpen: this.isWelcomeModalOpen,
      onClose: this.dismissWelcomeModal,
    };
  }

  welcome = () => {
    this.isWelcomeModalOpen = true;
  };

  private dismissWelcomeModal = () => {
    this.isWelcomeModalOpen = false;
  };

  /**
   * PaywallModal
   */
  paywallModalType: PaywallModalType = 'routine-limit';
  isPaywallModalOpen = false;
  paywallOnClose: () => void = () => null;
  paywallOnCancel: () => void = () => null;
  get paywallProps(): PaywallModalProps {
    return {
      type: this.paywallModalType,
      isOpen: this.isPaywallModalOpen,
      onClose: () => {
        this.paywallOnClose();
      },
      onCancel: () => {
        this.paywallOnCancel();
      },
    };
  }

  paywall = (props: Omit<PaywallModalProps, 'isOpen' | 'onClose'>) => {
    this.paywallModalType = props.type;
    this.isPaywallModalOpen = true;
    this.paywallOnClose = () => {
      this.dismissPaywallModal();
    };
    this.paywallOnCancel = () => {
      props.onCancel();
      this.dismissPaywallModal();
    };
  };

  private dismissPaywallModal = () => {
    this.isPaywallModalOpen = false;
  };

  /**
   * ConfimationModal
   */
  isConfimationModalOpen = false;
  confirmationTitle = '';
  confirmationSubtitle = '';
  confirmationSubmitTitle = '';
  confirmationOnConfirm: () => void = () => null;

  get confirmationProps(): ConfirmationModalProps {
    return {
      title: this.confirmationTitle,
      subtitle: this.confirmationSubtitle,
      submitButtonTitle: this.confirmationSubmitTitle,
      isOpen: this.isConfimationModalOpen,
      onConfirm: () => {
        this.confirmationOnConfirm();
      },
      onClose: () => {
        this.dismissConfirmationModal();
      },
    };
  }

  confirmation = (props: Omit<ConfirmationModalProps, 'isOpen' | 'onClose'>) => {
    this.confirmationTitle = props.title;
    this.confirmationSubtitle = props.subtitle;
    this.confirmationSubmitTitle = props.submitButtonTitle;
    this.confirmationOnConfirm = () => {
      props.onConfirm();
      this.dismissConfirmationModal();
    };
    this.isConfimationModalOpen = true;
  };

  private dismissConfirmationModal = () => {
    this.isConfimationModalOpen = false;
  };

  /**
   * DestructiveConfimationModal
   */
  isDestructiveConfimationModalOpen = false;
  destructiveConfirmationTitle = '';
  destructiveConfirmationSubtitle = '';
  destructiveConfirmationSubmitTitle = '';
  destructiveConfirmationOnConfirm: () => void = () => null;

  get destructiveConfirmationProps(): DestructiveConfirmationModalProps {
    return {
      title: this.destructiveConfirmationTitle,
      subtitle: this.destructiveConfirmationSubtitle,
      submitButtonTitle: this.destructiveConfirmationSubmitTitle,
      isOpen: this.isDestructiveConfimationModalOpen,
      onConfirm: () => {
        this.destructiveConfirmationOnConfirm();
      },
      onClose: () => {
        this.dismissDestructiveConfirmationModal();
      },
    };
  }

  desctructiveConfirmation = (
    props: Omit<DestructiveConfirmationModalProps, 'isOpen' | 'onClose'>,
  ) => {
    this.destructiveConfirmationTitle = props.title;
    this.destructiveConfirmationSubtitle = props.subtitle;
    this.destructiveConfirmationSubmitTitle = props.submitButtonTitle;
    this.destructiveConfirmationOnConfirm = () => {
      props.onConfirm();
      this.dismissDestructiveConfirmationModal();
    };
    this.isDestructiveConfimationModalOpen = true;
  };

  private dismissDestructiveConfirmationModal = () => {
    this.isDestructiveConfimationModalOpen = false;
  };

  /**
   * MobileNavBarModal
   */
  isMobileNavBarModalOpen = false;
  get mobileNavBarModalProps(): MobileNavBarProps {
    return {
      isOpen: this.isMobileNavBarModalOpen,
      onClose: this.dismissMobileNavBarModal,
    };
  }

  openMobileNavBarModal = () => {
    this.isMobileNavBarModalOpen = true;
  };

  private dismissMobileNavBarModal = () => {
    this.isMobileNavBarModalOpen = false;
  };

  /**
   * CoachInviteModal
   */
  private isCoachInviteModalOpen = false;
  private coachName?: string;
  private coachProfilePic?: string;
  private onAcceptCoachInvite?: () => void;
  private onDeclineCoachInvite?: () => void;

  get coachInviteProps(): CoachInviteModalProps {
    return {
      coachName: this.coachName,
      coachProfilePic: this.coachProfilePic,
      onAcceptInvite: this.onAcceptCoachInvite ?? (() => null),
      onDeclineInvite: this.onDeclineCoachInvite ?? (() => null),
      isOpen: this.isCoachInviteModalOpen,
      onClose: this.dismissCoachInviteModal,
    };
  }

  showCoachInviteModal = (props: Omit<CoachInviteModalProps, 'isOpen' | 'onClose'>) => {
    this.coachName = props.coachName;
    this.coachProfilePic = props.coachProfilePic;
    this.onAcceptCoachInvite = props.onAcceptInvite;
    this.onDeclineCoachInvite = props.onDeclineInvite;
    this.isCoachInviteModalOpen = true;
  };

  dismissCoachInviteModal = () => {
    this.isCoachInviteModalOpen = false;
  };

  /**
   * UserListModal
   */
  isUserListModalOpen = false;
  userListMode: UserListMode = 'likes';
  userListUsername: string = '';
  userListWorkoutId: string = '';

  get userListProps(): UserListModalProps {
    return {
      vm:
        this.userListMode === 'followers'
          ? new FollowerListViewModel({ username: this.userListUsername })
          : new UserListViewModel({
              mode: this.userListMode,
              username: this.userListUsername,
              workoutId: this.userListWorkoutId,
            }),
      isOpen: this.isUserListModalOpen,
      onClose: this.dismissUserListModal,
    };
  }

  showUserListModal(props: { mode: 'likes'; workoutId: string }): void;
  showUserListModal(props: { mode: 'following' | 'followers'; username: string }): void;
  showUserListModal(props: { mode: UserListMode; username?: string; workoutId?: string }) {
    this.userListMode = props.mode;
    this.userListUsername = props.username ?? '';
    this.userListWorkoutId = props.workoutId ?? '';
    this.isUserListModalOpen = true;
  }

  dismissUserListModal = () => {
    this.isUserListModalOpen = false;
  };

  /**
   * ExerciseLibraryModal
   */
  isExerciseLibraryModalOpen = false;
  hidePlusIcons?: boolean;
  selectedExerciseId?: string;
  onExerciseSelected?: (exerciseId: string) => void;
  onCreateExercisePressed?: () => void;

  get ExerciseLibraryProps(): ExerciseLibraryModalProps {
    return {
      vm: new ExerciseLibraryViewModel(),
      onSelectExercise: this.onExerciseSelected,
      isOpen: this.isExerciseLibraryModalOpen,
      hidePlusIcons: this.hidePlusIcons,
      selectedExerciseId: this.selectedExerciseId,
      onClose: () => {
        this.dismissExerciseLibraryModal();
      },
      onCreateExercise: this.onCreateExercisePressed,
    };
  }

  openExerciseLibraryModal = ({
    hidePlusIcons,
    onExerciseSelected,
    selectedExerciseId,
    onCreateExercisePressed,
  }: {
    hidePlusIcons?: boolean;
    onExerciseSelected?: (exerciseId: string) => void;
    selectedExerciseId?: string;
    onCreateExercisePressed?: () => void;
  }) => {
    this.isExerciseLibraryModalOpen = true;
    this.hidePlusIcons = hidePlusIcons;
    this.selectedExerciseId = selectedExerciseId;
    this.onExerciseSelected = onExerciseSelected;
    this.onCreateExercisePressed = onCreateExercisePressed;
  };

  private dismissExerciseLibraryModal = () => {
    this.isExerciseLibraryModalOpen = false;
  };

  /**
   * CustomExerciseModal
   */

  onCustomExerciseCreated?: (exerciseTemplateId: string) => void;

  isCustomExerciseModalModalOpen = false;
  get CustomExerciseModalProps(): CustomExerciseModalProps {
    return {
      vm: new CustomExerciseViewModel(),
      isOpen: this.isCustomExerciseModalModalOpen,
      onClose: this.dismissCustomExerciseModalModal,
      onExerciseCreated: this.onCustomExerciseCreated,
    };
  }

  openCustomExerciseModal = (onCustomExerciseCreated?: (exerciseTemplateId: string) => void) => {
    this.onCustomExerciseCreated = onCustomExerciseCreated;
    this.isCustomExerciseModalModalOpen = true;
  };

  private dismissCustomExerciseModalModal = () => {
    this.isCustomExerciseModalModalOpen = false;
  };

  /**
   *  CommentModal
   */
  isCommentModalOpen = false;
  commentModalWorkoutId: string | undefined;

  get commentModalProps(): CommentModalProps | undefined {
    if (!this.commentModalWorkoutId) return;
    return {
      isOpen: this.isCommentModalOpen,
      onClose: this.dismissCommentModal,
      vm: new CommentModalViewModel({ workoutId: this.commentModalWorkoutId }),
    };
  }

  openCommentModal = (workoutId: string) => {
    this.isCommentModalOpen = true;
    this.commentModalWorkoutId = workoutId;
  };

  private dismissCommentModal = () => {
    this.isCommentModalOpen = false;
    this.commentModalWorkoutId = undefined;
  };
}

export const modal = new ModalManagerState();

export const ModalManager = observer(() => {
  const router = useRouter();

  return (
    <>
      <MobileNavBarModal {...modal.mobileNavBarModalProps} />
      <ConfirmationModal {...modal.confirmationProps} />
      <AlertModal {...modal.alertProps} />
      <DestructiveConfirmationModal {...modal.destructiveConfirmationProps} />
      <PaywallModal {...modal.paywallProps} />
      <WelcomeModal {...modal.welcomeProps} />
      <UserListModal {...modal.userListProps} />
      <OkModal {...modal.okProps} />
      <RoutineListModal {...modal.routineListProps} />
      <ExerciseLibraryModal {...modal.ExerciseLibraryProps} />
      <CustomExerciseModal {...modal.CustomExerciseModalProps} />
      {!router.pathname.startsWith('/coach') && <CoachInviteModal {...modal.coachInviteProps} />}
      {!!modal.commentModalProps && <CommentModal {...modal.commentModalProps} />}
    </>
  );
});
