import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { Modal } from 'styleguide/Modal';
import { H1, TextMD, TextLG } from 'styleguide/Texts';
import { themeState } from 'styleguide/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  border-radius: 10px;
  padding: 16px;
  @media (max-width: 1024px) {
    width: 300px;
  }
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LowerContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
`;

export interface WelcomeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WelcomeModal = observer(({ isOpen, onClose }: WelcomeModalProps) => {
  return (
    <ThemeProvider theme={themeState.current}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Container>
          <UpperContainer>
            <H1 style={{ fontSize: 50, marginBottom: 20 }}>👋</H1>
          </UpperContainer>
          <MiddleContainer>
            <H1 style={{ marginBottom: 20 }}>{'Welcome to the Hevy website!'}</H1>
            <TextMD style={{ color: colors.neutral400, marginBottom: 16 }}>
              Thanks for trying out our website!
            </TextMD>
            <TextMD style={{ color: colors.neutral400, marginBottom: 16 }}>
              Here you can manage your routines, interact with Hevy athletes, and see your exercise
              analytics on a big screen!
            </TextMD>
            <TextMD style={{ color: colors.neutral400, marginBottom: 16 }}>
              You’ll notice there are still some features in the mobile app that aren’t available on
              the web. Overtime, we’ll close this gap and you’ll be able to do everything on the web
              that you can do in the mobile app.
            </TextMD>
            <TextMD style={{ color: colors.neutral400, marginBottom: 16 }}>
              Please give us feedback, report bugs, and send us any ideas you have to improve the
              website.
            </TextMD>
            <TextLG style={{ color: colors.neutral400 }}>Hevy Team</TextLG>
          </MiddleContainer>
          <LowerContainer>
            <PrimaryButton style={styles.button} title={'Continue'} onClick={onClose} />
          </LowerContainer>
        </Container>
      </Modal>
    </ThemeProvider>
  );
});

const styles: { [key: string]: CSSProperties } = {
  button: {
    display: 'flex',
    width: 200,
  },
};
