import axios, { AxiosError, AxiosResponse } from 'axios';
import { env } from 'env';
import Cookies from 'js-cookie';
import {
  UserPreferences,
  CustomExercise,
  Routine,
  RoutineFolder,
  RoutineLocationUpdate,
  RoutineSync,
  RoutineUpdate,
  UserHevyProSubcription,
  Workout,
  SearchUser,
  WorkoutComment,
  FollowingStatusMap,
  AccountUpdate,
  UserProfile,
  PublicUserProfile,
  PublicWorkout,
  CreateCustomExerciseRequest,
  UserExerciseSet,
  StripeCustomerPortalSessionRequest,
  StripeCustomerPortalSessionResponse,
  ClientsCoachData,
  GetInviteResponse,
  ClientInvite,
  ExerciseTemplateUnit,
  UserAccountResponse,
  FollowCountsResponse,
  UserWorkoutMetricsType,
  WorkoutMetric,
  UserCalendarWorkout,
  PaddlePrice,
  PaddlePromoCodeDetails,
  PaddlePlanChangeRequest,
  CoachJoinFormData,
  UserKeyValues,
} from 'hevy-shared';

export interface APIData<T> {
  data: T;
}

const api = axios.create({
  baseURL: env.apiUrl,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': env.apiKey,
    'Hevy-Platform': 'web',
  },
});

let authToken: string | undefined = Cookies.get('auth-token');
const setAuthToken = (newAuthToken?: string) => {
  authToken = newAuthToken;
};

const requestHeadersModifier =
  (withHeaders: any) =>
  (config: any): any => {
    const headers = config.headers || {};
    const modification = withHeaders(headers);

    return modification ? { ...config, headers: { ...(headers || {}), ...modification } } : config;
  };

const authenticationRequestInterceptor = requestHeadersModifier(() => {
  return authToken ? { 'auth-token': authToken } : null;
});

api.interceptors.request.use(authenticationRequestInterceptor);

const attachErrorInterceptor = (onError: (error: AxiosError) => void) => {
  api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      onError(error);
      return Promise.reject(error);
    },
  );
};

const signup = (email: string, password: string, recaptchaToken: string, gympassUserId?: string) =>
  api.post('signup', { email, password, recaptchaToken, gympassUserId });

const login = (
  emailOrUsername: string,
  password: string,
): Promise<APIData<{ auth_token: string }>> => api.post('login', { emailOrUsername, password });

export interface SocialLoginResponse {
  auth_token: string;
  is_new_user: boolean;
}

const signInWithApple = ({
  identityToken,
  email,
  gympassUserId,
}: {
  identityToken: string;
  email?: string;
  gympassUserId?: string;
}): Promise<APIData<SocialLoginResponse>> =>
  api.post('sign_in_with_apple_web', { identityToken, email, gympassUserId });

const signInWithGoogle = (
  email: string,
  userId: string,
  idToken: string,
  gympassUserId?: string,
): Promise<APIData<SocialLoginResponse>> =>
  api.post('sign_in_with_google', {
    email,
    userId,
    idToken,
    source: 'web',
    generateUsername: true,
    gympassUserId,
  });

const generatePasswordRecoveryEmail = (email: string) => api.post('recover_password', { email });
const generateDownloadLinkEmail = () => api.post('email_download_link');

const getAccount = (): Promise<APIData<UserAccountResponse>> => api.get('user/account');
const updateAccount = (account: AccountUpdate) => api.put('account', { account });
const getPresignedUrl = (fileName: string): Promise<APIData<{ presigned_url: string }>> =>
  api.post('presigned_url', { file_name: fileName });
const updatePassword = (props: { currentPassword: string; newPassword: string }) =>
  api.put('update_password_with_password', props);
const updatePasswordWithToken = (props: { token: string; password: string }) =>
  api.post('update_password', props);
const updateUsername = (username: string, isInitialOnboardingUsername?: boolean) =>
  api.put('username', { username, isInitialOnboardingUsername });

const uploadFileWithPresignedUrl = ({ presignedUrl, file }: { presignedUrl: string; file: File }) =>
  axios.put(presignedUrl, file);

const getUserSubscription = (): Promise<APIData<UserHevyProSubcription>> =>
  api.get('user_subscription');

const getUserPreferences = (): Promise<APIData<UserPreferences>> => api.get('user_preferences');
const updateUserPreferences = (
  props: Omit<UserPreferences, 'username'>,
): Promise<APIData<UserPreferences>> => api.put('user_preferences', props);

const getUserKeyValues = (): Promise<APIData<UserKeyValues>> => api.get('user_key_values');

const getRoutinesSync = (routineIdUpdatedAtMap: {
  [routineId: string]: string;
}): Promise<APIData<RoutineSync>> => api.post('routines_sync_batch', routineIdUpdatedAtMap);

const getRoutineWithShortId = (shortId: string): Promise<APIData<{ routine: Routine }>> =>
  api.get(`routine_with_short_id/${shortId}`);

const getRoutine = (routineId: string): Promise<APIData<{ routine: Routine }>> =>
  api.get(`routine/${routineId}`);

const postRoutine = (routine: RoutineUpdate) =>
  api.post('routine', { routine }, { params: { sendSyncEventToMobileApp: true } });

const postRoutineCopy = (props: {
  routineId: string;
  ignoreValues: boolean;
  index: number | null;
  copiedRoutineTitle?: string;
}) => api.post('routine_copy', props, { params: { sendSyncEventToMobileApp: true } });

const updateRoutine = (routineId: string, routine: RoutineUpdate) =>
  api.put(`routine/${routineId}`, { routine }, { params: { sendSyncEventToMobileApp: true } });

const deleteRoutine = (routineId: string) =>
  api.delete(`routine/${routineId}`, { params: { sendSyncEventToMobileApp: true } });

const getRoutineFolders = (): Promise<APIData<RoutineFolder[]>> => api.get('routine_folders');

const postRoutineFolder = (folder: { title: string }) =>
  api.post('routine_folder', { folder }, { params: { sendSyncEventToMobileApp: true } });

const updateRoutineFolder = (props: { folderId: string; title: string }) =>
  api.put('routine_folder', props, { params: { sendSyncEventToMobileApp: true } });

const deleteRoutineFolder = (folderId: string | number) =>
  api.delete(`routine_folder/${folderId}`, { params: { sendSyncEventToMobileApp: true } });

const updateRoutineLocations = (locations: RoutineLocationUpdate[]) =>
  api.put('routine_locations', { locations }, { params: { sendSyncEventToMobileApp: true } });

const updateRoutineFolderOrder = (reorders: Array<{ folderId: number; index: number }>) =>
  api.put('routine_folder_order', { reorders }, { params: { sendSyncEventToMobileApp: true } });

const getCustomExerciseTemplates = (): Promise<APIData<CustomExercise[]>> =>
  api.get('custom_exercise_templates');

const getExerciseTemplateUnits = (): Promise<APIData<ExerciseTemplateUnit[]>> =>
  api.get('exercise_template_units');

const postFeedback = (title: string, message: string) => api.post('feedback', { title, message });

const getFeedPaged = (lessThanIndex?: number): Promise<APIData<{ workouts: Workout[] }>> =>
  api.get(lessThanIndex ? `feed_workouts_paged/${lessThanIndex}` : 'feed_workouts_paged');

const userSearch = (search: string): Promise<APIData<SearchUser[]>> => api.get(`users/${search}`);
const getRecommendedUsers = (): Promise<APIData<SearchUser[]>> => api.get('recommended_users');

const getFollowingStatuses = (): Promise<APIData<FollowingStatusMap>> =>
  api.get('following_statuses');
const getFollowing = (username: string): Promise<APIData<SearchUser[]>> =>
  api.get(`following/${username}`);
const followUser = (usernameToFollow: string) => api.post('follow', { username: usernameToFollow });
const unfollowUser = (usernametoUnfollow: string) =>
  api.post('unfollow', { username: usernametoUnfollow });
const getFollowersPaged = (username: string, offset: number) =>
  api.get(`followers_paged/${username}/${offset}`);
const searchFollowers = (username: string, search: string) =>
  api.get(`followers_search/${username}/${search}`);
const getFollowCounts = (): Promise<APIData<FollowCountsResponse>> => api.get('follow_counts');

const getUserProfile = (username: string): Promise<APIData<UserProfile>> =>
  api.get(`user_profile/${username}`);
const getUserPublicApiKey = (): Promise<APIData<{ public_api_key: string | null }>> =>
  api.get(`user_public_api_key`);
const createUserPublicApiKey = (): Promise<APIData<{ public_api_key: string }>> =>
  api.post(`user_public_api_key`);
const deleteUserPublicApiKey = () => api.delete(`user_public_api_key`);
const getPublicUserProfile = (username: string): Promise<APIData<PublicUserProfile>> =>
  api.get(`public_user_profile/${username}`);
const getUserWorkoutImages = (username: string, limit: number): Promise<APIData<string[]>> =>
  api.get(`/user_workout_images/${username}/${limit}`);
const getUserWorkoutMetrics = (
  metricType: UserWorkoutMetricsType,
  startUnix: number,
  endUnix: number,
): Promise<APIData<WorkoutMetric[]>> =>
  api.get(`user_workout_metrics/${metricType}/${startUnix}/${endUnix}`);

const getUserCalendarWorkouts = (
  month: number,
  year: number,
): Promise<APIData<UserCalendarWorkout[]>> => api.get(`user_calendar_workouts/${year}/${month}`);

const getWorkout = (workoutId: string): Promise<APIData<Workout | PublicWorkout>> =>
  api.get(`workout/${workoutId}`);

const getUserWorkoutsPaged = (params: {
  username: string;
  limit: number;
  offset: number;
}): Promise<APIData<{ workouts: Workout[] }>> => api.get('user_workouts_paged', { params });
const getWorkoutComments = (workoutId: string): Promise<APIData<WorkoutComment[]>> =>
  api.get(`workout_comments/${workoutId}`);
const postWorkoutComment = (workoutId: string, comment: string) =>
  api.post('workout_comment', { workoutId, comment });
const deleteWorkoutComment = (commentId: number) => api.delete(`workout_comment/${commentId}`);

const likeWorkout = (workoutId: string) => api.post(`workout/like/${workoutId}`);
const unlikeWorkout = (workoutId: string) => api.post(`workout/unlike/${workoutId}`);

const getWorkoutLikes = (workoutId: string): Promise<APIData<SearchUser[]>> =>
  api.get(`workout_likes/${workoutId}`);

const getWorkoutCount = (): Promise<APIData<{ workout_count: number }>> => api.get('workout_count');
const getWorkoutsBatch = (startingIndex: number): Promise<APIData<Workout[]>> =>
  api.get(`workouts_batch/${startingIndex}`);

const postCustomExerciseTemplate = (
  exercise: CreateCustomExerciseRequest,
): Promise<APIData<{ id: string }>> => api.post('custom_exercise_template', { exercise });
const deleteCustomExerciseTemplate = (templateId: string) =>
  api.delete(`/custom_exercise_template/${templateId}`);

const getUserExerciseSets = (
  exerciseTemplateId: string,
  since: string,
): Promise<APIData<UserExerciseSet[]>> =>
  api.get(`user_exercise_sets/${exerciseTemplateId}/${since}`);

const getPaddlePrices = (): Promise<APIData<PaddlePrice[]>> => api.get(`paddle_prices`);
const getPaddleUrls = (): Promise<APIData<{ cancel_url: string; update_url?: string }>> =>
  api.get(`user/paddle_urls`);
const changePaddlePlan = (request: PaddlePlanChangeRequest): Promise<APIData<{}>> =>
  api.post(`user/change_paddle_plan`, request);
const cancelPaddlePlan = (): Promise<APIData<{}>> => api.delete(`user/paddle_plan`);
const getPaddlePromoCodeDetails = (couponCode: string): Promise<APIData<PaddlePromoCodeDetails>> =>
  api.get(`paddle_promo_code_details/${couponCode}`);

const createStripeCustomerPortalSession = (
  props: StripeCustomerPortalSessionRequest,
): Promise<APIData<StripeCustomerPortalSessionResponse>> =>
  api.post(`create_stripe_customer_portal_session`, props);

const getCoachInvites = (): Promise<APIData<ClientInvite[]>> => api.get('client_invites');
const getCoachInfoForInvite = (inviteShortId: string): Promise<APIData<GetInviteResponse>> =>
  api.get(`/invite/${inviteShortId}`);
const getCoachJoinFormMetadata = (coachUsername: string): Promise<APIData<CoachJoinFormData>> =>
  api.get(`/coach/join_form_metadata/${coachUsername}`);
const acceptCoachInvite = (coachUsername: string): Promise<APIData<void>> =>
  api.get(`/accept_client_invite/${coachUsername}`);
const getBecomeClient = (coachUsername: string): Promise<APIData<void>> =>
  api.get(`/become_client/${coachUsername}`);
const acceptCoachInviteWithShortId = (shortId: string): Promise<APIData<void>> =>
  api.get(`/accept_client_invite_with_short_id/${shortId}`);
const declineCoachInvite = (coachUsername: string): Promise<APIData<void>> =>
  api.delete(`/client_invites/${coachUsername}`);
const declineCoachInviteWithShortId = (shortId: string): Promise<APIData<void>> =>
  api.delete(`/client_invites_with_short_id/${shortId}`);
const getCoach = (): Promise<APIData<ClientsCoachData>> => api.get('clients_coach');

const deleteAccount = () => api.delete('/user');

const API = {
  setAuthToken,
  signup,
  login,
  signInWithApple,
  signInWithGoogle,
  generatePasswordRecoveryEmail,
  generateDownloadLinkEmail,
  getAccount,
  updateAccount,
  updatePassword,
  updatePasswordWithToken,
  updateUsername,
  getPresignedUrl,
  uploadFileWithPresignedUrl,
  getUserSubscription,
  getUserPreferences,
  updateUserPreferences,
  getUserKeyValues,
  getRoutinesSync,
  getRoutine,
  getRoutineWithShortId,
  postRoutine,
  postRoutineCopy,
  updateRoutine,
  deleteRoutine,
  getRoutineFolders,
  postRoutineFolder,
  updateRoutineFolder,
  deleteRoutineFolder,
  updateRoutineLocations,
  updateRoutineFolderOrder,
  getCustomExerciseTemplates,
  getExerciseTemplateUnits,
  postFeedback,
  getFeedPaged,
  userSearch,
  getRecommendedUsers,
  getFollowingStatuses,
  getFollowing,
  getFollowCounts,
  followUser,
  unfollowUser,
  getFollowersPaged,
  searchFollowers,
  getUserProfile,
  getUserPublicApiKey,
  createUserPublicApiKey,
  deleteUserPublicApiKey,
  getPublicUserProfile,
  getUserWorkoutImages,
  getUserWorkoutMetrics,
  getUserCalendarWorkouts,
  getWorkout,
  getUserWorkoutsPaged,
  getWorkoutComments,
  getWorkoutLikes,
  postWorkoutComment,
  deleteWorkoutComment,
  likeWorkout,
  unlikeWorkout,
  getWorkoutCount,
  getWorkoutsBatch,
  postCustomExerciseTemplate,
  deleteCustomExerciseTemplate,
  getUserExerciseSets,
  getPaddlePrices,
  getPaddleUrls,
  changePaddlePlan,
  cancelPaddlePlan,
  getPaddlePromoCodeDetails,
  createStripeCustomerPortalSession,
  getCoachInvites,
  getCoachInfoForInvite,
  getCoachJoinFormMetadata,
  getBecomeClient,
  acceptCoachInvite,
  acceptCoachInviteWithShortId,
  getCoach,
  declineCoachInvite,
  declineCoachInviteWithShortId,
  deleteAccount,
  attachErrorInterceptor,
};

export default API;
